define("ember-radio-button/templates/components/radio-button", ["exports"], function (exports) {
  "use strict";

  exports.__esModule = true;
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 19,
              "column": 0
            }
          },
          "moduleName": "ember-radio-button/templates/components/radio-button.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("label");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createAttrMorph(element0, 'for');
          morphs[2] = dom.createMorphAt(element0, 1, 1);
          morphs[3] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["ember-radio-button ", ["subexpr", "if", [["get", "checked", ["loc", [null, [2, 40], [2, 47]]]], ["get", "checkedClass", ["loc", [null, [2, 48], [2, 60]]]]], [], ["loc", [null, [2, 35], [2, 62]]]], " ", ["get", "joinedClassNames", ["loc", [null, [2, 65], [2, 81]]]]]]], ["attribute", "for", ["get", "radioId", ["loc", [null, [2, 91], [2, 98]]]]], ["inline", "radio-button-input", [], ["class", ["subexpr", "@mut", [["get", "radioClass", ["loc", [null, [4, 14], [4, 24]]]]], [], []], "id", ["subexpr", "@mut", [["get", "radioId", ["loc", [null, [5, 11], [5, 18]]]]], [], []], "autofocus", ["subexpr", "@mut", [["get", "autofocus", ["loc", [null, [6, 18], [6, 27]]]]], [], []], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [7, 17], [7, 25]]]]], [], []], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [8, 13], [8, 17]]]]], [], []], "required", ["subexpr", "@mut", [["get", "required", ["loc", [null, [9, 17], [9, 25]]]]], [], []], "tabindex", ["subexpr", "@mut", [["get", "tabindex", ["loc", [null, [10, 17], [10, 25]]]]], [], []], "groupValue", ["subexpr", "@mut", [["get", "groupValue", ["loc", [null, [11, 19], [11, 29]]]]], [], []], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [12, 14], [12, 19]]]]], [], []], "ariaLabelledby", ["subexpr", "@mut", [["get", "ariaLabelledby", ["loc", [null, [13, 23], [13, 37]]]]], [], []], "ariaDescribedby", ["subexpr", "@mut", [["get", "ariaDescribedby", ["loc", [null, [14, 24], [14, 39]]]]], [], []], "changed", "changed"], ["loc", [null, [3, 4], [15, 27]]]], ["content", "yield", ["loc", [null, [17, 4], [17, 13]]]]],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@1.13.13",
          "loc": {
            "source": null,
            "start": {
              "line": 19,
              "column": 0
            },
            "end": {
              "line": 33,
              "column": 0
            }
          },
          "moduleName": "ember-radio-button/templates/components/radio-button.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "radio-button-input", [], ["class", ["subexpr", "@mut", [["get", "radioClass", ["loc", [null, [21, 12], [21, 22]]]]], [], []], "id", ["subexpr", "@mut", [["get", "radioId", ["loc", [null, [22, 9], [22, 16]]]]], [], []], "autofocus", ["subexpr", "@mut", [["get", "autofocus", ["loc", [null, [23, 16], [23, 25]]]]], [], []], "disabled", ["subexpr", "@mut", [["get", "disabled", ["loc", [null, [24, 15], [24, 23]]]]], [], []], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [25, 11], [25, 15]]]]], [], []], "required", ["subexpr", "@mut", [["get", "required", ["loc", [null, [26, 15], [26, 23]]]]], [], []], "tabindex", ["subexpr", "@mut", [["get", "tabindex", ["loc", [null, [27, 15], [27, 23]]]]], [], []], "groupValue", ["subexpr", "@mut", [["get", "groupValue", ["loc", [null, [28, 17], [28, 27]]]]], [], []], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [29, 12], [29, 17]]]]], [], []], "ariaLabelledby", ["subexpr", "@mut", [["get", "ariaLabelledby", ["loc", [null, [30, 21], [30, 35]]]]], [], []], "ariaDescribedby", ["subexpr", "@mut", [["get", "ariaDescribedby", ["loc", [null, [31, 22], [31, 37]]]]], [], []], "changed", "changed"], ["loc", [null, [20, 2], [32, 25]]]]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@1.13.13",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 34,
            "column": 0
          }
        },
        "moduleName": "ember-radio-button/templates/components/radio-button.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [1, 6], [1, 14]]]]], [], 0, 1, ["loc", [null, [1, 0], [33, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});