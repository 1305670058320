define('ember-get-helper/utils/register-helper', ['exports', 'ember'], function (exports, _ember) {
	exports.registerHelper = registerHelper;

	function registerHelperIteration1(name, helperFunction) {
		//earlier versions of ember with htmlbars used this
		_ember['default'].HTMLBars.helpers[name] = helperFunction;
	}

	function registerHelperIteration2(name, helperFunction) {
		//registerHelper has been made private as _registerHelper
		//this is kept here if anyone is using it
		_ember['default'].HTMLBars.registerHelper(name, helperFunction);
	}

	function registerHelperIteration3(name, helperFunction) {
		//latest versin of ember uses this
		_ember['default'].HTMLBars._registerHelper(name, helperFunction);
	}

	function registerHelperIteration4(name, helperFunction) {
		//same as iteration 3 and only used as a documentation point
		_ember['default'].HTMLBars._registerHelper(name, helperFunction);
	}

	function registerHelper(name, helperFunction) {
		if (_ember['default'].HTMLBars._registerHelper) {
			if (_ember['default'].HTMLBars.helpers) {
				registerHelperIteration1(name, helperFunction);
			} else {
				if (_ember['default'].Helper) {
					registerHelperIteration4(name, helperFunction);
				} else {
					registerHelperIteration3(name, helperFunction);
				}
			}
		} else if (_ember['default'].HTMLBars.registerHelper) {
			registerHelperIteration2(name, helperFunction);
		}
	}
});