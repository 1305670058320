define('ember-get-helper/helpers/get', ['exports', 'ember-get-helper/utils/get-stream'], function (exports, _emberGetHelperUtilsGetStream) {
  exports.getHelper = getHelper;

  function getHelper(params, hash, options, env) {
    if (arguments.length === 0) {
      return;
    }

    var view = env.data.view;
    var obj = params[0];
    var key = params[1];

    return (0, _emberGetHelperUtilsGetStream['default'])(view, obj, key);
  }

  exports['default'] = getHelper;
});